import '../facilitate/AssessmentDetails.scss';

import { AssessmentStatus } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, FlashType, withAuthContext } from '@amzn/awscat-react-components';
import { Box, Button, FormField, Link, Select, SelectProps, SpaceBetween } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { ValidationError } from 'class-validator';
import { DateTime } from 'luxon';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import a2sApolloClient from '../../../api/a2s/ApolloClient';
import { UPDATE_ASSESSMENT_BASE } from '../../../api/a2s/ApolloQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import { isApnUser } from '../../../common/Utils';
import { errorLookup } from '../../../common/ValidatorUtils';
import AssessmentPermissionManager from '../../../common/auth/AssessmentPermissionManager';
import rumClient from '../../../common/monitoring/RumClient';
import { CustomerSegmentId } from '../../../models/CustomerProfile';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../../common/RequestStatusFlashbar';
import { clearAppHelpPanel, openAppHelpPanel, updateAppHelpPanel } from '../../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { AssessmentViewModel } from '../AssessmentViewModel';
import { getExcludedAndRequiredMetadataKeys, validateAssessmentAndMetadataKeys } from '../Utils';
import { AssessmentCreateState, assessmentCreateStateToAssessmentInput, defaultAssessmentCreateState } from '../create/AssessmentCreateState';
import AssessmentDetailsPanel from '../create/AssessmentDetailsPanel';
import { updateAssessmentBaseSuccessful } from '../facilitate/CurrentAssessmentSlice';
import AssessmentDetailsHeader from '../facilitate/header/AssessmentDetailsHeader';

type RouteParams = {
    assessmentId: string;
};

const AssessmentUpdate: FunctionComponent<AppLabelsContextInterface & AuthContextInterface> = ({ appLabels, auth }): JSX.Element => {
    const history = useHistory();
    const { assessmentId } = useParams<RouteParams>();
    const [state, setState] = useState(defaultAssessmentCreateState);
    const [stateChanged, setStateChanged] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const currentAssessment = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const myUserId = auth?.getUserInfo()?.userId;
    const isPartnerUser = isApnUser(myUserId);
    const assessmentPermissionManager = new AssessmentPermissionManager(myUserId, currentAssessment?.assessmentPermissions);
    const isReadOnly = !assessmentPermissionManager.hasUpdatePemission;
    const dispatch = useAppDispatch();
    const [selectedStatusOption, setSelectedStatusOption] = useState<AssessmentStatus>(AssessmentStatus.COMPLETED);
    const getErrorText = errorLookup<AssessmentCreateState>(validationErrors);
    const statusOptions: SelectProps.Options = [{ label: AssessmentStatus.IN_PROGRESS }, { label: AssessmentStatus.COMPLETED }];
    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const assessmentTemplates = currentAssessment?.template ? [currentAssessment?.template] : [];

    const { excludedMetadataKeys, requiredMetadataKeys } = getExcludedAndRequiredMetadataKeys(currentAssessment?.template, isPartnerUser);

    const [updateAssessmentBase, { loading }] = useMutation(UPDATE_ASSESSMENT_BASE, {
        client: a2sApolloClient,
        onCompleted: (data) => {
            const response = data.updateAssessment;
            if (response) {
                dispatch(updateAssessmentBaseSuccessful({ assessmentId, response }));
                setState({ ...state, status: response.status });
                setRequestStatus({
                    ...requestStatus,
                    loading,
                    messageType: FlashType.success,
                });
            }
        },
        onError: (error) => {
            setRequestStatus({
                ...requestStatus,
                loading,
                messageType: FlashType.error,
                messageHeader: appLabels.assessment.update.error_updating_assessment,
                messageContent: error.message ?? '',
            });
            rumClient.recordError(error);
        },
    });

    const onCancel = useCallback(async () => {
        history.goBack();
    }, [history]);

    const onUpdate = useCallback(async () => {
        try {
            state.status = selectedStatusOption;
            const validationErrors = await validateAssessmentAndMetadataKeys(state, excludedMetadataKeys, requiredMetadataKeys);
            setValidationErrors(validationErrors);
            if (!(validationErrors && validationErrors.length)) {
                setRequestStatus({
                    ...requestStatus,
                    messageHeader: appLabels.assessment.update.update_assessment_successful,
                });
                updateAssessmentBase({
                    variables: {
                        id: assessmentId,
                        input: assessmentCreateStateToAssessmentInput(state),
                    },
                });
            }
        } catch (error: any) {
            rumClient.recordError(error);
            setRequestStatus({
                messageType: FlashType.error,
                loading: false,
                messageHeader: appLabels.assessment.update.error_updating_assessment,
                messageContent: error.message ?? '',
            });
        }
    }, [
        appLabels.assessment.update.error_updating_assessment,
        appLabels.assessment.update.update_assessment_successful,
        assessmentId,
        excludedMetadataKeys,
        requiredMetadataKeys,
        requestStatus,
        selectedStatusOption,
        state,
        updateAssessmentBase,
    ]);

    const onComplete = useCallback(async () => {
        try {
            state.status = AssessmentStatus.COMPLETED;
            const validationErrors = await validateAssessmentAndMetadataKeys(state, excludedMetadataKeys, requiredMetadataKeys);
            setValidationErrors(validationErrors);
            if (!(validationErrors && validationErrors.length)) {
                setRequestStatus({
                    ...requestStatus,
                    messageHeader: appLabels.assessment.complete.complete_assessment_successful,
                    messageContent: appLabels.assessment.complete.status_constraint,
                });
                updateAssessmentBase({
                    variables: {
                        id: assessmentId,
                        input: assessmentCreateStateToAssessmentInput(state),
                    },
                });
            }
        } catch (error: any) {
            rumClient.recordError(error);
            setRequestStatus({
                messageType: FlashType.error,
                loading: false,
                messageHeader: appLabels.assessment.complete.error_completing_assessment,
                messageContent: error.message ?? '',
            });
        }
    }, [
        appLabels.assessment.complete.complete_assessment_successful,
        appLabels.assessment.complete.error_completing_assessment,
        appLabels.assessment.complete.status_constraint,
        assessmentId,
        excludedMetadataKeys,
        requiredMetadataKeys,
        requestStatus,
        state,
        updateAssessmentBase,
    ]);

    const onStateChange = useCallback(
        (subStateValue: Partial<AssessmentCreateState>) => {
            const newState = {
                ...state,
                ...subStateValue,
            };
            setState(newState);
            setSelectedStatusOption(newState.status as AssessmentStatus);
            setStateChanged(true);
        },
        [state]
    );

    const mapAssessmentViewModelToState = useCallback((currentAssessment: AssessmentViewModel, isReadOnly: boolean): AssessmentCreateState => {
        // Drop all null metadata entries
        // ToDo: fix A2S to prevent generation of null metadata entries
        const metadata = currentAssessment.metadata?.filter((m) => !!m) || [];
        const state: AssessmentCreateState = {
            account: {
                id: currentAssessment.customerAccountID,
                organizationId: '',
                referenceId: null,
                accountName: currentAssessment.accountCustomerName,
                ownerId: null,
                createdBy: null,
                updatedBy: null,
                territory: null,
                website: null,
                industry: null,
                geo: null,
                region: null,
                segment: null,
            },
            description: currentAssessment.description || '',
            applicability: currentAssessment.template?.applicability,
            applicabilityPrompts: [], // applicability prompts not used on the update page, as it still uses A2S template
            mapProgramEngagementId: currentAssessment.mapProgramEngagementId ? currentAssessment.mapProgramEngagementId : undefined,
            opportunity: currentAssessment.opportunity || null,
            internalContact: currentAssessment.internalContact ? currentAssessment.internalContact : undefined,
            workshopDate: currentAssessment.workshopDate ? DateTime.fromJSDate(new Date(currentAssessment.workshopDate)).toISODate() : '',
            readoutDate: currentAssessment.readoutDate ? DateTime.fromJSDate(new Date(currentAssessment.readoutDate)).toISODate() : '',
            deliveredBy: currentAssessment.deliveredBy
                ? {
                      label: currentAssessment.deliveredBy || '',
                      value: currentAssessment.deliveredBy || '',
                  }
                : null,
            isDemoTest: currentAssessment.isDemoTest || false,
            type: currentAssessment.type || '',
            version: currentAssessment.version || '',
            status: currentAssessment.status,
            isReadOnly: isReadOnly,
            optOutSolutionsRecommendation: currentAssessment.optOutSolutionsRecommendation,
            customerProfile: {
                targetSegmentId: currentAssessment.targetSegmentId as CustomerSegmentId,
                customerCloudMaturity: null,
                customerPlanAndAlignmentExists: null,
            },
            metadata,
        };
        return state;
    }, []);

    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };
        dispatch(
            updateAppHelpPanel({
                content: appLabels.assessment.update.info_panel_text,
            })
        );

        if (currentAssessment) {
            setState(mapAssessmentViewModelToState(currentAssessment, isReadOnly));
        }
        return cleanup;
    }, [appLabels.assessment.update.info_panel_text, currentAssessment, dispatch, isReadOnly, mapAssessmentViewModelToState]);

    if (!currentAssessment) {
        return null;
    }

    return (
        <div className='awscat-assessment-results'>
            <AssessmentDetailsHeader showLiveSession={false} readOnly={true} showSnapshots={false} />
            <Box variant='h2' margin={{ top: 'm', bottom: 'm' }}>
                {appLabels.assessment.update.title}
                <Box margin={{ left: 'xs' }} display='inline-block'>
                    <Link variant='info' id='assessment-update-info-link' onFollow={() => dispatch(openAppHelpPanel())}>
                        {appLabels.common.info}
                    </Link>
                </Box>
            </Box>
            <div className='assessment-form'>
                <SpaceBetween direction='vertical' size='l'>
                    <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
                    <AssessmentDetailsPanel
                        validationErrors={validationErrors}
                        assessmentTemplates={assessmentTemplates}
                        state={state}
                        onChange={onStateChange}
                        isReadOnly={isReadOnly}
                    ></AssessmentDetailsPanel>
                    {currentAssessment.status === AssessmentStatus.COMPLETED && (
                        <FormField label='Status' constraintText={appLabels.assessment.complete.status_constraint} errorText={getErrorText('status')}>
                            <Select
                                selectedOption={{ label: selectedStatusOption }}
                                onChange={(event) => {
                                    onStateChange({ status: event.detail.selectedOption.label as AssessmentStatus });
                                }}
                                selectedAriaLabel={'Status'}
                                options={statusOptions}
                            />
                        </FormField>
                    )}
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='s'>
                            <Button variant='link' onClick={onCancel} data-testid='btn-cancel-update-assessment'>
                                {appLabels.assessment.update.cancel_button_text}
                            </Button>
                            <Button
                                variant='primary'
                                disabled={!stateChanged}
                                loading={loading}
                                onClick={onUpdate}
                                data-testid='btn-update-assessment'
                            >
                                {appLabels.assessment.update.save_button_text}
                            </Button>
                            {currentAssessment.status === AssessmentStatus.IN_PROGRESS && (
                                <Button variant='primary' onClick={onComplete} data-testid='btn-complete-assessment'>
                                    {appLabels.assessment.complete.complete_button_text}
                                </Button>
                            )}
                        </SpaceBetween>
                    </Box>
                </SpaceBetween>
            </div>
        </div>
    );
};

export default withAuthContext(withAppLabelsContext(AssessmentUpdate));
