import {
    OptionalAssessmentMetadataKey,
    ResponseType,
    AssessmentTemplate as TmAssessmentTemplate,
} from '@amzn/aws-assessment-template-management-service-typescript-client';
import { Opportunity } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, FlashType, withAuthContext } from '@amzn/awscat-react-components';
import {
    Alert,
    Checkbox,
    ColumnLayout,
    Container,
    DatePicker,
    FormField,
    Header,
    Input,
    RadioGroup,
    Select,
    SpaceBetween,
} from '@amzn/awsui-components-react';
import { Opportunity as CasOpportunity, ListOpportunitiesCommand } from '@amzn/customer-account-service-typescript-client';
import { ValidationError } from 'class-validator';
import { useMemo, useState } from 'react';
import { useCallback, useEffect } from 'react';

import { AssessmentCreateState } from './AssessmentCreateState';
import { casOpportunityToSelectionOption, opportunityToSelectionOption, selectionOptionToOpportunity } from './AssessmentDetailsPanelHelpers';
import { YesNoValue } from '../../../api/a2s/A2STypes';
import { getCustomerAccountServiceLambdaClient } from '../../../api/cas/CustomerAccountServiceClient';
import { assessmentTypeRecommendationEngineRegistry } from '../../../assessments/recommendation/RecommendationEngines';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import { LoadingStatus } from '../../../common/RequestUtils';
import { isApnUser } from '../../../common/Utils';
import { errorLookup } from '../../../common/ValidatorUtils';
import rumClient from '../../../common/monitoring/RumClient';
import { DeliveredByValue } from '../../../models/Assessment';
import TemplateConstants from '../../administration/manage-templates/edit-template/TemplateConstants';
import { getDescriptorForLocale } from '../../administration/manage-templates/edit-template/TemplateUtils';
import { LocalizationContextInterface, withLocalizationContext } from '../../localization/LocalizationContext';
import { getSfdcIdFromAccountId } from '../Utils';

// This template has been copied from `AssessmentDetailsPanel.tsx` and modified to support Template Management templates.
// The assessment create flow is fully migrated to use the Template Management template.
// However, the assessment edit/update flow isn't. And because both of those flows use `AssessmentDetailsPanel.tsx`,
// needed to split them for now

type OnChangeHandler = (subStateValue: Partial<AssessmentCreateState>) => void;

type DetailPanelProps = {
    assessmentTemplates: TmAssessmentTemplate[];
    state: AssessmentCreateState;
    onChange: OnChangeHandler;
    validationErrors: ValidationError[];
    isReadOnly: boolean;
} & AppLabelsContextInterface &
    LocalizationContextInterface &
    AuthContextInterface;

const DetailPanelForNewTemplate = ({
    assessmentTemplates,
    state,
    onChange,
    validationErrors,
    appLabels,
    isReadOnly,
    locale,
    auth,
}: DetailPanelProps): JSX.Element => {
    const detailPanelData = state;
    const { customerProfile, account: customerAccount } = detailPanelData;
    const isPartner = useMemo(() => isApnUser(auth?.getUserInfo()?.userId), [auth]);

    useEffect(() => {
        // At initialization, set the default targetSegmentId based on customer profile setup on the previous page
        const defaultTargetSegmentId = assessmentTypeRecommendationEngineRegistry.getOverriddenTargetSegmentId(detailPanelData.type, customerProfile);
        onChange({ customerProfile: { ...customerProfile, targetSegmentId: defaultTargetSegmentId } });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const metadataIsRequired = useCallback(
        (metadataKey: string) => {
            const assessmentTemplate = assessmentTemplates?.find((t) => t.type === detailPanelData.type);
            const requiredMetadataKeys = assessmentTemplate?.metadataConfig?.requiredMetadataKeys ?? [];
            return requiredMetadataKeys.some((k: string) => k === metadataKey);
        },
        [assessmentTemplates, detailPanelData.type]
    );

    const metadataIsExcluded = useCallback(
        (metadataKey: string) => {
            const assessmentTemplate = assessmentTemplates?.find((t) => t.type === detailPanelData.type);
            const excludeOptionalMetadataKeys = assessmentTemplate.config?.assessmentMetadataConfig?.excludedMetadataPrompts ?? [];
            return excludeOptionalMetadataKeys.some((k: string) => k === metadataKey);
        },
        [assessmentTemplates, detailPanelData.type]
    );

    // Opportunity  should not be shown to partners
    const shouldAddOpportunity: boolean = useMemo(
        () => !isPartner && !metadataIsExcluded(OptionalAssessmentMetadataKey.OpportunityId),
        [isPartner, metadataIsExcluded]
    );
    const [opportunitiesForAccount, setOpportunitiesForAccount] = useState<CasOpportunity[]>([]);
    const [opportuinitiesLoadingStatus, setOpportunitiesLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NotLoaded);

    /**
     * useEffect for retrieving and setting the opportunities  Depends on the current customer account and the current assessment type
     * If the current assessment type does not use opportunities, retrieval is skipped
     */
    useEffect(() => {
        // If there's no customer account, then don't get oppt/eng. (This can happen during unit tests)
        if (!customerAccount?.id) {
            return;
        }

        const sfdcCustomerAccountId = getSfdcIdFromAccountId(customerAccount.id);

        /**
         * Retrieves opportunities associated with the current customer account. Sets corresponding variables
         * @returns void
         */
        async function retrieveAndSetOpportunities(): Promise<void> {
            const casClient = await getCustomerAccountServiceLambdaClient();
            try {
                setOpportunitiesLoadingStatus(LoadingStatus.Loading);
                const listOpportunitiesResponse = await casClient.send(new ListOpportunitiesCommand({ accountId: sfdcCustomerAccountId }));
                const retrievedOpportunities = listOpportunitiesResponse.opportunities;
                let nextPageToken = listOpportunitiesResponse.nextPageToken;
                while (nextPageToken) {
                    const nextPageResponse = await casClient.send(new ListOpportunitiesCommand({ accountId: sfdcCustomerAccountId, nextPageToken }));
                    retrievedOpportunities.push(...nextPageResponse.opportunities);
                    nextPageToken = nextPageResponse.nextPageToken;
                }
                const updatedOpportunities = [
                    ...(retrievedOpportunities || []),
                    { opportunityId: '000000000000000000', opportunityName: 'No Associated Opportunity' },
                ];
                setOpportunitiesForAccount(updatedOpportunities);
                setOpportunitiesLoadingStatus(LoadingStatus.Loaded);

                // If opportunity is required, but there are no opportunities for the account, show a warning
                if (metadataIsRequired(OptionalAssessmentMetadataKey.OpportunityId)) {
                    if (retrievedOpportunities.length === 0) {
                        setRequestStatus({
                            loading: false,
                            messageType: FlashType.info,
                            messageHeader: appLabels.assessment.create.opportunity.error_no_opportunities,
                            messageContent: appLabels.assessment.create.opportunity.error_no_opportunities_content,
                        });
                    }
                }
            } catch (err) {
                console.error(err);
                rumClient.recordError(err);
                // TODO : uncomment after permission verification in all stages ( beta/gamma/prod)
                // setRequestStatus({
                //     loading: false,
                //     messageType: FlashType.error,
                //     messageHeader: appLabels.assessment.create.opportunity.error_loading,
                //     messageContent: (err as Error).message || '',
                // });
                // setOpportunitiesLoadingStatus(LoadingStatus.FailedToLoad);
                setOpportunitiesForAccount([
                    {
                        opportunityId: '000000000000000000',
                        opportunityName: 'No Associated Opportunity',
                    },
                ]);
            }
        }
        if (shouldAddOpportunity) {
            retrieveAndSetOpportunities();
        }
    }, [customerAccount?.id, shouldAddOpportunity, appLabels.assessment.create, metadataIsRequired]);

    const onDetailPanelChange = useCallback(
        (subStateValue: Partial<AssessmentCreateState>) => {
            onChange(subStateValue);
        },
        [onChange]
    );

    const getErrorText = errorLookup<AssessmentCreateState>(validationErrors);

    const getAssessmentTypeRecommendation = useCallback(() => {
        const recommendation = assessmentTypeRecommendationEngineRegistry.getRecommendationAlert(detailPanelData.type, customerProfile, appLabels);
        if (!recommendation) {
            return null;
        }

        return (
            <Alert data-testid='alert-type-not-recommended' type={recommendation.alertType}>
                {recommendation.description}
            </Alert>
        );
    }, [appLabels, customerProfile, detailPanelData.type]);

    const includeApplicabilitySelections = useCallback(() => {
        // Check if assessment template has defined applicability criteria.
        // If defined, create selections based on the configured prompts.
        const applicabilityPrompts = detailPanelData.applicabilityPrompts;
        if (!applicabilityPrompts?.length) {
            return null;
        }

        return applicabilityPrompts.map((applicabilityPrompt) => {
            if (applicabilityPrompt.responseType !== ResponseType.SingleSelection) {
                // For now, only support single selection response type.
                // Applicability prompt conditions (e.g. targetSegmentId) defines the attribute to be saved to assessment metadata.
                // ToDo: make detailPanelData state support dynamic attributes instead of hardcoding targetSegmentId
                return null;
            }

            const selectionItems = applicabilityPrompt.selections.map((selection) => {
                const selectionLabel = getDescriptorForLocale(selection.selectionLabel, locale).description;
                const { selectionId } = selection;
                return { label: selectionLabel, value: selectionId };
            });
            const selectionDescription = getDescriptorForLocale(applicabilityPrompt.selectionDescription, locale).description;

            // Currently, each applicability prompt saves only one condition
            const applicabilityPromptKey = applicabilityPrompt.a2sId;
            const applicabilityPromptQuestionText = getDescriptorForLocale(applicabilityPrompt.descriptors, locale).description;

            const selectedOption = selectionItems.find((item) => item.value === customerProfile[applicabilityPromptKey]);
            return (
                <FormField label={applicabilityPromptQuestionText} stretch={true}>
                    <Select
                        placeholder={selectionDescription || ''}
                        options={selectionItems}
                        selectedOption={selectedOption}
                        onChange={({ detail }) =>
                            onDetailPanelChange({ customerProfile: { ...customerProfile, [applicabilityPrompt.a2sId]: detail.selectedOption.value } })
                        }
                        expandToViewport
                        selectedAriaLabel={appLabels.user_actions.selected}
                        data-testid='assessment-target-segment-select'
                    />
                </FormField>
            );
        });
    }, [appLabels.user_actions.selected, customerProfile, locale, detailPanelData.applicabilityPrompts, onDetailPanelChange]);

    /**
     * Adds the form fields for opportunity
     */
    const addOpportunity = useCallback(() => {
        const selectedOpportunity: Opportunity = detailPanelData.opportunity;
        return (
            <>
                {shouldAddOpportunity && (
                    <FormField
                        label={`${appLabels.assessment.create.opportunity.field_label} ${
                            metadataIsRequired(OptionalAssessmentMetadataKey.OpportunityId) ? '*' : ''
                        }`}
                        stretch={true}
                        errorText={getErrorText('opportunity')}
                    >
                        <Select
                            placeholder={appLabels.assessment.create.opportunity.placeholder}
                            selectedOption={(selectedOpportunity && opportunityToSelectionOption(selectedOpportunity)) || null}
                            options={opportunitiesForAccount.map(casOpportunityToSelectionOption)}
                            onChange={({ detail: { selectedOption } }) => {
                                onDetailPanelChange({ opportunity: selectionOptionToOpportunity(selectedOption) });
                            }}
                            loadingText={opportuinitiesLoadingStatus === LoadingStatus.Loading ? appLabels.common.loading : ''}
                            data-testid='assessment-opportunity-selection'
                        />
                    </FormField>
                )}
            </>
        );
    }, [
        detailPanelData.opportunity,
        metadataIsRequired,
        opportuinitiesLoadingStatus,
        opportunitiesForAccount,
        shouldAddOpportunity,
        onDetailPanelChange,
        appLabels.assessment.create,
        appLabels.common.loading,
        getErrorText,
    ]);

    const addMetadata = useCallback(() => {
        const assessmentTemplate = assessmentTemplates?.find((t) => t.type === detailPanelData.type);
        const customMetadata = assessmentTemplate?.config?.assessmentMetadataConfig?.metadataPrompts || [];
        if (!customMetadata) {
            return null;
        }

        return customMetadata.map((metadataPrompt) => {
            // A metadata prompt has a few parts:
            // 1. metadataPromptKey - this is the ID against which the response is saved. Because this is the same behavior as
            //                        a normal prompt, this ID comes from the a2sId of the metadata prompt
            // 2. metadataPromptDefaultValue - this is the default value for the metadata prompt. This is stored in the prompt metadata
            //                        for the metadata prompt itself
            // 3. metadataPromptKeyName - a user-friendly indication for the info they should enter
            // 4. metadataPromptHint - a user-friendly placeholder response

            const metadataPromptKey = metadataPrompt.a2sId;

            // The default value of the prompt is stored in the prompt's metadata
            const metadataPromptDefault = metadataPrompt.metadata?.find((m) => m.key === TemplateConstants.METADATA_PROMPT_DEFAULT_VALUE_KEY);
            const metadataPromptDefaultValue: string =
                metadataPromptDefault?.value || getDescriptorForLocale(metadataPromptDefault?.descriptors, locale)?.name;

            let metadataState = detailPanelData.metadata?.find((m) => m?.key === metadataPromptKey);
            if (!metadataState && metadataPromptDefaultValue) {
                // create a new metadata entry with default
                metadataState = { key: metadataPromptKey, value: metadataPromptDefaultValue };
            }

            const updateMetadataList = (key: string, newValue: string) => {
                if (!metadataState || !detailPanelData.metadata?.some((m) => m.key === key)) {
                    // does not have initial default value, create the new metadata
                    return detailPanelData.metadata?.concat([{ key, value: newValue }]);
                }
                return detailPanelData.metadata?.map((metadata) => {
                    if (metadata.key === key) {
                        return { key, value: newValue };
                    }
                    return metadata;
                });
            };

            const metadataPromptDescriptor = getDescriptorForLocale(metadataPrompt.descriptors, locale);
            const { description: metadataPromptLabel, hint: metadataPromptHint } = metadataPromptDescriptor;

            switch (metadataPrompt.responseType) {
                case ResponseType.Text:
                    return (
                        <FormField label={metadataPromptLabel} stretch={true} errorText={getErrorText('metadata')}>
                            <Input
                                data-testid={`assessment-${metadataPromptKey}`}
                                placeholder={metadataPromptHint ?? ''}
                                value={metadataState?.value}
                                onChange={({ detail: { value } }) => onDetailPanelChange({ metadata: updateMetadataList(metadataPromptKey, value) })}
                                disabled={isReadOnly}
                            />
                        </FormField>
                    );
                case ResponseType.YesNo:
                    return (
                        <FormField label={metadataPromptLabel} stretch={true} errorText={getErrorText('metadata')}>
                            <RadioGroup
                                data-testid={`assessment-${metadataPromptKey}`}
                                value={metadataState?.value}
                                items={[
                                    {
                                        value: YesNoValue.YES,
                                        label: appLabels.common.yes,
                                        disabled: isReadOnly,
                                    },
                                    {
                                        value: YesNoValue.NO,
                                        label: appLabels.common.no,
                                        disabled: isReadOnly,
                                    },
                                ]}
                                onChange={({ detail: { value } }) => onDetailPanelChange({ metadata: updateMetadataList(metadataPromptKey, value) })}
                            />
                        </FormField>
                    );
                case ResponseType.DateValue:
                    return (
                        <FormField label={metadataPromptLabel} stretch={true} errorText={getErrorText('metadata')}>
                            <DatePicker
                                data-testid={`assessment-${metadataPromptKey}`}
                                value={metadataState?.value}
                                openCalendarAriaLabel={(selectedDate) => {
                                    return appLabels.common.choose_date + (selectedDate ? `, ${appLabels.common.selected_date} ${selectedDate}` : '');
                                }}
                                nextMonthAriaLabel={appLabels.common.next_month}
                                placeholder={appLabels.common.date_placeholder}
                                previousMonthAriaLabel={appLabels.assessment.create.previous_month}
                                todayAriaLabel={appLabels.assessment.create.today}
                                disabled={isReadOnly}
                                onChange={({ detail: { value } }) => onDetailPanelChange({ metadata: updateMetadataList(metadataPromptKey, value) })}
                            />
                        </FormField>
                    );
                default:
                    rumClient.recordError(`addMetadata: invalid metadataPrompt.responseType=${metadataPrompt.responseType}`);
                    return null;
            }
        });
    }, [
        appLabels.assessment.create.previous_month,
        appLabels.assessment.create.today,
        appLabels.common.choose_date,
        appLabels.common.date_placeholder,
        appLabels.common.next_month,
        appLabels.common.no,
        appLabels.common.selected_date,
        appLabels.common.yes,
        assessmentTemplates,
        detailPanelData.metadata,
        detailPanelData.type,
        getErrorText,
        isReadOnly,
        onDetailPanelChange,
        locale,
    ]);

    return (
        <Container header={<Header variant='h2'>{appLabels.assessment.create.details}</Header>}>
            <SpaceBetween size='l'>
                {getAssessmentTypeRecommendation()}
                <FormField label={`${appLabels.assessment.create.details_account}*`} stretch={true} errorText={getErrorText('account')}>
                    <Input
                        data-testid='assessment-account-name-input'
                        value={detailPanelData.account?.accountName || ''}
                        placeholder={''}
                        disabled={true}
                    />
                </FormField>
                {includeApplicabilitySelections()}
                <FormField label={`${appLabels.assessment.create.details_description}*`} stretch={true} errorText={getErrorText('description')}>
                    <Input
                        data-testid='assessment-description-input'
                        value={detailPanelData.description}
                        ariaRequired={true}
                        placeholder={appLabels.assessment.create.details_description_placeholder}
                        onChange={({ detail: { value } }) => onDetailPanelChange({ description: value })}
                        disabled={isReadOnly}
                    />
                </FormField>
                {addOpportunity()}
                {metadataIsExcluded('mapProgramEngagementId') ? null : (
                    <FormField label={`${appLabels.assessment.create.map_id}*`} stretch={true} errorText={getErrorText('mapProgramEngagementId')}>
                        <Input
                            data-testid='assessment-map-id-input'
                            value={detailPanelData.mapProgramEngagementId}
                            placeholder={appLabels.assessment.create.map_id_placeholder}
                            onChange={({ detail: { value } }) => onDetailPanelChange({ mapProgramEngagementId: value })}
                            disabled={isReadOnly}
                        />
                    </FormField>
                )}
                {metadataIsExcluded('internalContact') ? null : (
                    <FormField label={`${appLabels.assessment.create.customer_contact}*`} stretch={true} errorText={getErrorText('internalContact')}>
                        <Input
                            data-testid='assessment-internal-contact-input'
                            placeholder={appLabels.assessment.create.customer_contact_placeholder}
                            value={detailPanelData.internalContact}
                            onChange={({ detail: { value } }) => onDetailPanelChange({ internalContact: value })}
                            disabled={isReadOnly}
                        />
                    </FormField>
                )}
                {metadataIsExcluded('workshopDate') && metadataIsExcluded('readoutDate') ? null : (
                    <FormField stretch={true} constraintText={appLabels.assessment.create.workshopdate_readoutdate_constraint}>
                        <ColumnLayout columns={2}>
                            {metadataIsExcluded('workshopDate') ? null : (
                                <FormField
                                    label={`${appLabels.assessment.create.workshop_date}*`}
                                    stretch={true}
                                    className='date-time-container'
                                    errorText={getErrorText('workshopDate')}
                                >
                                    <DatePicker
                                        data-testid='assessment-workshop-date-picker'
                                        onChange={({ detail: { value } }) => onDetailPanelChange({ workshopDate: value })}
                                        value={detailPanelData.workshopDate || ''}
                                        openCalendarAriaLabel={(selectedDate) => {
                                            return (
                                                appLabels.common.choose_date +
                                                (selectedDate ? `, ${appLabels.common.selected_date} ${selectedDate}` : '')
                                            );
                                        }}
                                        nextMonthAriaLabel={appLabels.common.next_month}
                                        placeholder={appLabels.common.date_placeholder}
                                        previousMonthAriaLabel={appLabels.assessment.create.previous_month}
                                        todayAriaLabel={appLabels.assessment.create.today}
                                        disabled={isReadOnly}
                                    />
                                </FormField>
                            )}
                            {metadataIsExcluded('readoutDate') ? null : (
                                <FormField
                                    label={`${appLabels.assessment.create.readout_date}*`}
                                    stretch={true}
                                    className='date-time-container'
                                    errorText={getErrorText('readoutDate')}
                                >
                                    <DatePicker
                                        data-testid='assessment-readout-date-picker'
                                        onChange={({ detail: { value } }) => onDetailPanelChange({ readoutDate: value })}
                                        value={detailPanelData.readoutDate || ''}
                                        openCalendarAriaLabel={(selectedDate) => {
                                            return (
                                                appLabels.common.choose_date +
                                                (selectedDate ? `, ${appLabels.common.selected_date} ${selectedDate}` : '')
                                            );
                                        }}
                                        nextMonthAriaLabel={appLabels.common.next_month}
                                        placeholder={appLabels.common.date_placeholder}
                                        previousMonthAriaLabel={appLabels.assessment.create.previous_month}
                                        todayAriaLabel={appLabels.assessment.create.today}
                                        disabled={isReadOnly}
                                    />
                                </FormField>
                            )}
                        </ColumnLayout>
                    </FormField>
                )}
                {addMetadata()}
                <FormField label={`${appLabels.assessment.create.delivered_by}*`} stretch={true} errorText={getErrorText('deliveredBy')}>
                    <Select
                        data-testid='assessment-delivered-by-select'
                        selectedOption={detailPanelData.deliveredBy}
                        onChange={({ detail }) => onDetailPanelChange({ deliveredBy: detail.selectedOption })}
                        // ToDo: delivered by options should come from template - https://i.amazon.com/issues/A2T-1755
                        // Todo: display of delivered by options should be localized - https://i.amazon.com/issues/A2T-1883
                        options={[
                            { label: DeliveredByValue.MIGRATION_BDM, value: DeliveredByValue.MIGRATION_BDM },
                            { label: DeliveredByValue.PROSERVE, value: DeliveredByValue.PROSERVE },
                            { label: DeliveredByValue.TAM, value: DeliveredByValue.TAM },
                            { label: DeliveredByValue.ACCOUNT_SA, value: DeliveredByValue.ACCOUNT_SA },
                            { label: DeliveredByValue.MIGRATION_SA, value: DeliveredByValue.MIGRATION_SA },
                            { label: DeliveredByValue.WWSO_SPECIALIST, value: DeliveredByValue.WWSO_SPECIALIST },
                            { label: DeliveredByValue.CSM, value: DeliveredByValue.CSM },
                            { label: DeliveredByValue.AWS_PARTNER_ORGANIZATION, value: DeliveredByValue.AWS_PARTNER_ORGANIZATION },
                            { label: DeliveredByValue.AWS_PARNTER_NETWORK, value: DeliveredByValue.AWS_PARNTER_NETWORK },
                            { label: DeliveredByValue.MIGRATION_COMPENTENCY_PARTNER, value: DeliveredByValue.MIGRATION_COMPENTENCY_PARTNER },
                            { label: DeliveredByValue.OTHER, value: DeliveredByValue.OTHER },
                        ]}
                        selectedAriaLabel={appLabels.assessment.create.delivered_by}
                        disabled={isReadOnly}
                    />
                </FormField>
                <FormField constraintText={appLabels.assessment.create.demo_or_test_constraint} stretch={true}>
                    <Checkbox
                        data-testid='assessment-is-demo-test-check-box'
                        checked={state.isDemoTest}
                        onChange={({ detail: { checked } }) => onDetailPanelChange({ isDemoTest: checked })}
                        disabled={isReadOnly}
                    >
                        {appLabels.assessment.create.demo_or_test}
                    </Checkbox>
                </FormField>
                <FormField constraintText={appLabels.assessment.create.optout_recommendation_constraint} stretch={true}>
                    <Checkbox
                        data-testid='assessment-opt-out-solutions-recommendation-check-box'
                        checked={state.optOutSolutionsRecommendation}
                        onChange={({ detail: { checked } }) => onDetailPanelChange({ optOutSolutionsRecommendation: checked })}
                        disabled={isReadOnly}
                    >
                        {appLabels.assessment.create.optout_recommendation}
                    </Checkbox>
                </FormField>
            </SpaceBetween>
        </Container>
    );
};

export default withAuthContext(withLocalizationContext(withAppLabelsContext(DetailPanelForNewTemplate)));
