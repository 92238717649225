import { RecommendationOutput } from '@amzn/aws-assessment-recommendation-service-client';
import { AssessmentPrompt, AssessmentResponseTypes } from '@amzn/awscat-aws-assessment-service-typescript-client';

import { generateDocxConsolidatedActionsForCategory, generateDocxDetailedActionsForCategory } from './ReportHelpers';
import { RecommendedActionPriorityIntValues } from '../../../api/a2s/A2STypes';
import { getPreEventAnswers } from '../../../api/data-collection/DataCollectionClient';
import { AppLabels } from '../../../common/AppLabels';
import Constants from '../../../common/Constants';
import { toFixedDigits } from '../../../common/Utils';
import { AssessmentViewModel } from '../../../components/assessments/AssessmentViewModel';
import {
    QuestionResults,
    getMaxRatingInAssessment,
    getQuestionResults,
    getResponseComments,
    getResponseValue,
    isPromptConsideredOptional,
    isSectionConsideredOptional,
    isSectionShown,
} from '../../../components/assessments/Utils';
import { filterExcludedSections, getCommentsWithRatingGuide } from '../../../components/assessments/results/Utils';
import { getAssessmentMetadataProvider } from '../../../components/assessments/results/model/Assessment';
import { getPriorityOptions } from '../../../components/assessments/results/reviewall/report/new-recommended-actions/RecommendationConstants';
import { Colorizer } from '../Colorizer';
import { addCustomizations, generateReport } from '../GenerateReport';

function getDates(assessment: AssessmentViewModel, locale: string) {
    const params = {};
    params['[Date]'] = new Date().toDateString();
    params['[Workshop Date]'] = assessment.workshopDate ?? 'N/A';
    params['[Readout Date]'] = assessment.readoutDate ?? 'N/A';
    params['[Last Updated Date]'] = assessment.lastUpdated ?? 'N/A';
    params['[Created At Date]'] = new Date(assessment.createdAt * 1000)
        .toLocaleDateString(locale, { dateStyle: 'medium' })
        .toString()
        .replace(',', '');
    params['[Year]'] = new Date().getFullYear();

    return params;
}

async function getContacts(assessment: AssessmentViewModel) {
    const params = {};
    params['[Internal Contact]'] = assessment.internalContact ?? '';

    const preEvent = await getPreEventAnswers(assessment.id);
    const sponsorEmail = preEvent?.owners?.length ? preEvent?.owners.at(0) : 'N/A';
    params['[Sponsor Contact]'] = sponsorEmail ?? '';

    return params;
}

function getRadars(appLabels: AppLabels, assessment: AssessmentViewModel) {
    const params = {};

    const currentAssessmentSections = assessment?.template?.sections || [];
    const scoreMultiplier: number = 100 / getMaxRatingInAssessment(assessment);
    if (currentAssessmentSections.length > 0) {
        const excludeSections = assessment.template?.defaults?.report?.radar?.excludeSections;
        const includedSections = filterExcludedSections(currentAssessmentSections, excludeSections, assessment) || [];
        const sectionScores = includedSections.map((p) => (p.score ? p.score * scoreMultiplier : 0));
        const sectionScoreNames = includedSections.map((p) => p.label);

        if (includedSections.length > 0) {
            const sectionsRadarParams = {
                title: assessment?.template?.defaults?.report?.sectionLabel || appLabels.assessment.results.generate_report.parameters.perspectives,
                labels: sectionScoreNames,
                values: sectionScores,
                fillColor: '#FF9B00',
                maxValue: 100,
                numTickMarks: 5,
                // Optional formatting
                guidePercent: 0.8,
                guideColor: '#00FF00',
            };

            params['[RADAR-Perspectives]'] = sectionsRadarParams;
        }
    }

    const workstreams = assessment?.template?.workstreams || [];
    if (workstreams.length > 0) {
        const workstreamNames: string[] = workstreams.map((w) => w.name);
        const workstreamScores: number[] = workstreams.map((w) => (w.score ? w.score * scoreMultiplier : 0));

        const workstreamsRadarParams = {
            title: appLabels.assessment.results.generate_report.parameters.workstreams,
            labels: workstreamNames,
            values: workstreamScores,
            fillColor: '#FF9B00',
            maxValue: 100,
            numTickMarks: 5,
            // Optional formatting
            guidePercent: 0.8,
            guideColor: '#00FF00',
        };

        params['[RADAR-Workstreams]'] = workstreamsRadarParams;
    }

    return params;
}

function getHeatmap(appLabels: AppLabels, assessment: AssessmentViewModel, colorizer: Colorizer) {
    const params: { [key: string]: any } = {};
    const workstreams = assessment?.template?.workstreams || [];
    if (workstreams.length > 0) {
        for (const workstream of workstreams) {
            const workstreamData = {
                color: colorizer.colorize(workstream).replace('0x', '#'),
                // replace_text replaces the text placeholder with provided text.
                // E.g. "[FILL-business-case]" --> "Business Case"
                replace_text: `${appLabels.assessment.results.generate_report.parameters.average_score}`,
                // Group activities by workstream
                group: workstream?.name,
            };
            // Workstreams/activities might have the same name, so need to differentiate them with "average"
            if (workstream?.id) params[`[FILL-average-${workstream?.id}]`] = workstreamData;
            else params[`[FILL-average-${workstream?.name}]`] = workstreamData;

            for (const activity of workstream?.activities || []) {
                const activityColor = colorizer.colorize(activity).replace('0x', '#');
                const activityName = activity?.name;
                const data = {
                    color: activityColor,
                    replace_text: activityName,
                    group: workstream?.name || activityName,
                };
                if (activity?.id) params[`[FILL-${activity?.id}]`] = data;
                else params[`[FILL-${activity?.name}]`] = data;
            }
        }
    } else {
        const sections = assessment?.template?.sections || [];
        if (sections.length > 0) {
            for (const section of sections) {
                const sectionData = {
                    color: colorizer.colorize(section).replace('0x', '#'),
                    replace_text: `${appLabels.assessment.results.generate_report.parameters.average_score}`,
                    // Group categories by section
                    group: section?.label,
                };
                if (section?.id) params[`[FILL-average-${section?.id}]`] = sectionData;
                else params[`[FILL-average-${section?.label}]`] = sectionData;

                for (const category of section?.categories || []) {
                    const categoryColor = colorizer.colorize(category).replace('0x', '#');
                    const categoryName = category?.name;
                    const data = {
                        color: categoryColor,
                        replace_text: categoryName,
                        fontSize: 11,
                        group: section?.label || categoryName,
                    };
                    if (category?.id) params[`[FILL-${category?.id}]`] = data;
                    else params[`[FILL-${category?.name}]`] = data;
                }
            }
        }
    }

    return params;
}

function getBarCharts(appLabels: AppLabels, assessment: AssessmentViewModel, colorizer: Colorizer) {
    const params: { [key: string]: any } = {};

    const workstreams = assessment.template?.workstreams || [];
    if (workstreams.length > 0) {
        for (const workstream of workstreams) {
            const rgsBarChartParams: any = {
                title: `${workstream?.name || ''}`,
                minValue: 0,
                maxValue: assessment.template?.defaults?.report?.scores?.maxRating ?? Constants.ASSESSMENT_DEFAULT_MAX_SCORE,
                group: 'Scores',
            };

            const barValues = [];
            const barCategories = [];
            const barColors = [];
            const averageScore = workstream?.score || 0;
            barValues.push(parseFloat(toFixedDigits(averageScore, 1)));
            barCategories.push(appLabels.assessment.results.generate_report.parameters.average_score);
            barColors.push(colorizer.colorize({ score: averageScore }));
            for (const activity of workstream?.activities || []) {
                // Values need to have 1 fraction digit
                const score = activity?.score?.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) || '0';
                barValues.push(parseFloat(score));
                barCategories.push(activity?.name);
                barColors.push(colorizer.colorize(activity));
            }

            rgsBarChartParams['categories'] = barCategories;
            rgsBarChartParams['values'] = barValues;
            // Need to modify colors to start with # instead of 0x
            barColors.forEach((color: string, i: number) => {
                barColors[i] = color.replace('0x', '#');
            });
            rgsBarChartParams['barColors'] = barColors;

            if (workstream?.id) params[`[BAR-${workstream?.id}]`] = rgsBarChartParams;
            else params[`[BAR-${workstream?.name}]`] = rgsBarChartParams;
        }
    } else {
        const sections = assessment.template?.sections || [];
        for (const section of sections) {
            const rgsBarChartParams: any = {
                title: `${section?.label || ''}`,
                minValue: 0,
                maxValue: assessment.template?.defaults?.report?.scores?.maxRating ?? Constants.ASSESSMENT_DEFAULT_MAX_SCORE,
                group: 'Scores',
            };

            const barValues = [];
            const barCategories = [];
            const barColors = [];
            const averageScore = section?.score || 0;
            barValues.push(parseFloat(toFixedDigits(averageScore, 1)));
            barCategories.push(appLabels.assessment.results.generate_report.parameters.average_score);
            barColors.push(colorizer.colorize({ score: averageScore }));
            for (const category of section?.categories || []) {
                // Values need to have 1 fraction digit
                const score = category?.score?.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) || '0';
                barValues.push(parseFloat(score));
                barCategories.push(category?.name);
                barColors.push(colorizer.colorize(category));
            }

            rgsBarChartParams['categories'] = barCategories;
            rgsBarChartParams['values'] = barValues;

            // Need to modify colors to start with # instead of 0x
            barColors.forEach((color: string, i: number) => {
                barColors[i] = color.replace('0x', '#');
            });
            rgsBarChartParams['barColors'] = barColors;

            if (section?.id) params[`[BAR-${section?.id}]`] = rgsBarChartParams;
            else params[`[BAR-${section?.label}]`] = rgsBarChartParams;
        }
    }
    return params;
}

function getDetailsTable(appLabels: AppLabels, assessment: AssessmentViewModel) {
    const params: { [key: string]: any } = {};

    const tableParams = {
        columns: [
            appLabels.assessment.results.generate_report.parameters.section,
            appLabels.assessment.results.generate_report.parameters.category,
            appLabels.assessment.results.generate_report.parameters.score,
            appLabels.assessment.results.generate_report.parameters.summary_observations,
            appLabels.assessment.results.generate_report.parameters.action,
        ],
        // Table dimensions
        colWidth: [1, 2.5, 0.75, 3, 3],
    };

    // Include section/workstream label only the first time
    const sectionLabelsUsed = new Set<string>();

    const rows = [];

    if (assessment.template?.workstreams?.length > 0) {
        for (const workstream of assessment?.template?.workstreams || []) {
            for (const activity of workstream?.activities || []) {
                let sectionLabel = workstream?.name ?? '';
                if (sectionLabelsUsed.has(sectionLabel)) sectionLabel = '';
                else sectionLabelsUsed.add(sectionLabel);

                const observations = activity?.observations?.observations || '';
                const actionsText = activity?.actions?.actions?.map((action) => action?.text).join('\n') || '';

                const entry = [
                    sectionLabel,
                    activity?.name || '',
                    parseFloat(toFixedDigits(activity?.score, 1) || '0') || '',
                    observations,
                    actionsText,
                ];
                rows.push(entry);
            }
        }
    } else {
        for (const section of assessment?.template?.sections || []) {
            for (const category of section?.categories || []) {
                let sectionLabel = section?.label ?? '';
                if (sectionLabelsUsed.has(sectionLabel)) sectionLabel = '';
                else sectionLabelsUsed.add(sectionLabel);

                const observations = category?.observations?.observations || '';
                const actionsText = category?.actions?.actions?.map((action) => action?.text).join('\n') || '';

                const entry = [
                    sectionLabel,
                    category?.name || '',
                    parseFloat(toFixedDigits(category?.score, 1) || '0') || '',
                    observations,
                    actionsText,
                ];
                rows.push(entry);
            }
        }
    }

    tableParams['data'] = rows;
    params['[TABLE-Details]'] = tableParams;

    return params;
}

function getResponsesTable(appLabels: AppLabels, assessment: AssessmentViewModel) {
    const params: { [key: string]: any } = {};

    const tableParams = {
        columns: [
            appLabels.assessment.results.generate_report.parameters.section,
            appLabels.assessment.results.generate_report.parameters.category,
            appLabels.assessment.results.generate_report.parameters.question_number,
            appLabels.assessment.results.generate_report.parameters.question,
            appLabels.assessment.results.generate_report.parameters.response,
            appLabels.assessment.results.generate_report.parameters.comments,
        ],
        // Table dimensions
        colWidth: [1, 1, 0.5, 2, 1, 5],
    };

    // Include section label only the first time
    const sectionLabelsUsed = new Set<string>();

    const rows = [];
    const questionResults: QuestionResults[] = getQuestionResults(assessment, appLabels);
    for (const result of questionResults) {
        if (result?.sectionLabel) {
            let sectionLabel = result?.sectionLabel ?? '';
            if (sectionLabelsUsed.has(sectionLabel)) sectionLabel = '';
            else sectionLabelsUsed.add(sectionLabel);

            const entry = [
                sectionLabel,
                result?.categoryName ?? '',
                result?.questionNumber ?? '',
                result?.questionText ?? '',
                result?.response ?? '',
                getCommentsWithRatingGuide(result),
            ];
            rows.push(entry);
        }
    }

    tableParams['data'] = rows;
    params['[TABLE-Responses]'] = tableParams;

    return params;
}

function getRecommendedActionsTable(
    appLabels: AppLabels,
    assessment: AssessmentViewModel,
    rsIsEnabled: boolean,
    refIdToRecommendationsMap: { [key: string]: RecommendationOutput[] }
) {
    const params: { [key: string]: any } = {};
    const tableParams = {
        columns: [
            appLabels.assessment.results.generate_report.parameters.action,
            appLabels.assessment.results.generate_report.parameters.action_priority,
            appLabels.assessment.results.generate_report.parameters.action_effort,
            appLabels.assessment.results.generate_report.parameters.target_date,
            appLabels.assessment.results.generate_report.parameters.benefit,
            appLabels.assessment.results.generate_report.parameters.owner,
        ],
        // Table dimensions
        colWidth: [3, 0.75, 0.75, 1, 2.5, 1],
    };
    const tableData: string[][] = [];

    const workstreams = assessment?.template?.workstreams || [];
    if (workstreams.length > 0) {
        for (const workstream of workstreams) {
            for (const activity of workstream?.activities || []) {
                tableData.push(
                    ...generateDocxConsolidatedActionsForCategory(activity, rsIsEnabled, refIdToRecommendationsMap[activity.id], appLabels)
                );
            }
        }
    } else {
        const sections = assessment?.template?.sections || [];
        for (const section of sections) {
            for (const category of section?.categories || []) {
                tableData.push(
                    ...generateDocxConsolidatedActionsForCategory(category, rsIsEnabled, refIdToRecommendationsMap[category.id], appLabels)
                );
            }
        }
    }

    const priorityColumnNum = 1;
    const priorityToIndexMap = Object.assign(
        {},
        ...Object.values(getPriorityOptions(appLabels)).map((option, index) => ({ [option.label]: index + 1 }))
    );

    function comparePriorities(a: string[], b: string[]): number {
        const priority1 = a[priorityColumnNum];
        const priority2 = b[priorityColumnNum];
        const nullIndex = Object.keys(priorityToIndexMap).length + 1;

        // Find priority index in new RS priority labels; otherwise find priority index in old RS priority labels; if not able to find any matching label, assign highest index to it
        const priority1Value = priorityToIndexMap[priority1] || RecommendedActionPriorityIntValues[priority1] || nullIndex;
        const priority2Value = priorityToIndexMap[priority2] || RecommendedActionPriorityIntValues[priority2] || nullIndex;
        return priority1Value - priority2Value;
    }

    tableData.sort(comparePriorities);

    tableParams['data'] = tableData;
    params['[TABLE-RecommendedActions]'] = tableParams;
    params['[ACTIONS-RecommendedActions]'] = tableParams;
    return params;
}

function getDetails(
    appLabels: AppLabels,
    assessment: AssessmentViewModel,
    colorizer: Colorizer,
    rsIsEnabled: boolean,
    refIdToRecommendationsMap: { [key: string]: RecommendationOutput[] }
) {
    const params: { [key: string]: any } = {};

    const workstreamDetails = [];

    if (assessment.template?.workstreams?.length) {
        for (const workstream of assessment.template?.workstreams || []) {
            const workstreamInfo = {
                name: workstream?.name || '',
                backgroundColor: '#BFBFBF', // gray
                readinessActivities: [],
            };

            for (const activity of workstream?.activities || []) {
                const recommendedActionsTable = {
                    columns: [
                        appLabels.assessment.results.generate_report.parameters.action,
                        appLabels.assessment.results.generate_report.parameters.target_date,
                        appLabels.assessment.results.generate_report.parameters.benefit,
                        appLabels.assessment.results.generate_report.parameters.owner,
                    ],
                    colWidth: [3, 1, 2.5, 0.75],
                    data: generateDocxDetailedActionsForCategory(activity, rsIsEnabled, refIdToRecommendationsMap[activity.id], appLabels),
                };

                const activityInfo = {
                    name: activity?.name || '',
                    backgroundColor: colorizer.colorize(activity).replace('0x', '#'),
                    summaryObservationsHeading: `${appLabels.assessment.results.generate_report.parameters.summary_observations}:`,
                    summaryObservations: activity?.observations?.observations || '',
                    recommendedActionsHeading: `${appLabels.assessment.results.generate_report.parameters.recommended_actions}:`,
                    recommendedActionsTable: recommendedActionsTable,
                };

                workstreamInfo['readinessActivities'].push(activityInfo);
            }

            workstreamDetails.push(workstreamInfo);
        }
    } else {
        for (const section of assessment.template?.sections || []) {
            const sectionInfo = {
                name: section?.label || '',
                backgroundColor: '#BFBFBF', // gray
                readinessActivities: [],
            };

            for (const category of section?.categories || []) {
                const recommendedActionsTable = {
                    columns: [
                        appLabels.assessment.results.generate_report.parameters.action,
                        appLabels.assessment.results.generate_report.parameters.target_date,
                        appLabels.assessment.results.generate_report.parameters.benefit,
                        appLabels.assessment.results.generate_report.parameters.owner,
                    ],
                    colWidth: [3, 1, 2.5, 0.75],
                    data: generateDocxDetailedActionsForCategory(category, rsIsEnabled, refIdToRecommendationsMap[category.id], appLabels),
                };

                const activityInfo = {
                    name: category?.name || '',
                    backgroundColor: colorizer.colorize(category).replace('0x', '#'),
                    summaryObservationsHeading: `${appLabels.assessment.results.generate_report.parameters.summary_observations}:`,
                    summaryObservations: category?.observations?.observations || '',
                    recommendedActionsHeading: `${appLabels.assessment.results.generate_report.parameters.recommended_actions}:`,
                    recommendedActionsTable: recommendedActionsTable,
                };

                sectionInfo['readinessActivities'].push(activityInfo);
            }

            workstreamDetails.push(sectionInfo);
        }
    }

    params['[DETAILS-details]'] = workstreamDetails;

    return params;
}

function getRatings(appLabels: AppLabels, assessment: AssessmentViewModel) {
    const params: { [key: string]: any } = {};

    const questionResults: QuestionResults[] = getQuestionResults(assessment, appLabels);

    const workstreamsData = [];
    if (assessment.template?.workstreams?.length > 0) {
        for (const workstream of assessment.template?.workstreams || []) {
            const workstreamData = {
                name: workstream?.name || '',
                readinessActivities: [],
            };

            for (const activity of workstream?.activities || []) {
                const activityQuestions = questionResults.filter(
                    (question) => question?.workstreamName === workstream?.name && question.activityName === activity?.name
                );

                const activityData = {
                    name: activity?.name || '',
                };

                const questionTable = {
                    columns: [
                        appLabels.assessment.results.generate_report.parameters.question,
                        appLabels.assessment.results.generate_report.parameters.response,
                        appLabels.assessment.results.generate_report.parameters.comments,
                    ],
                    colWidth: [2.5, 1, 4],
                    data:
                        activityQuestions
                            ?.filter((question) => question.responseType === AssessmentResponseTypes.RATING)
                            ?.map((question) => [question.questionText, question.response, getCommentsWithRatingGuide(question)]) || [],
                };

                activityData['questionTable'] = questionTable;
                workstreamData['readinessActivities'].push(activityData);
            }

            workstreamsData.push(workstreamData);
        }
    } else {
        for (const section of assessment.template?.sections || []) {
            const sectionData = {
                name: section.label || '',
                readinessActivities: [],
            };

            for (const category of section?.categories || []) {
                const categoryQuestions = questionResults.filter(
                    (question) => question?.sectionLabel === section?.label && question?.categoryName === category?.name
                );

                const categoryData = {
                    name: category?.name || '',
                };

                const questionTable = {
                    columns: [
                        appLabels.assessment.results.generate_report.parameters.question,
                        appLabels.assessment.results.generate_report.parameters.response,
                        appLabels.assessment.results.generate_report.parameters.comments,
                    ],
                    colWidth: [2.75, 1, 4],
                    data:
                        categoryQuestions
                            ?.filter((question) => question.responseType === AssessmentResponseTypes.RATING)
                            ?.map((question) => [question.questionText, question.response, getCommentsWithRatingGuide(question)]) || [],
                };

                categoryData['questionTable'] = questionTable;
                sectionData['readinessActivities'].push(categoryData);
            }

            workstreamsData.push(sectionData);
        }
    }

    params['[RATINGS-responses]'] = workstreamsData;

    return params;
}

function getNonRatingQuestions(appLabels: AppLabels, assessment: AssessmentViewModel) {
    const params: { [key: string]: any } = {};

    const questionResults: QuestionResults[] = getQuestionResults(assessment, appLabels);
    const allQuestions = questionResults.filter((question) => question?.responseType !== AssessmentResponseTypes.RATING);

    const mainQuestions = allQuestions.filter((question) => !isPromptConsideredOptional(question?.promptId));

    const baseTableParams = {
        columns: [
            appLabels.assessment.results.generate_report.parameters.question_number,
            appLabels.assessment.results.generate_report.parameters.question,
            appLabels.assessment.results.generate_report.parameters.response,
            appLabels.assessment.results.generate_report.parameters.comments,
        ],
        colWidth: [0.5, 2, 3, 4],
    };

    params['[TABLE-text-responses]'] = {
        ...baseTableParams,
        data:
            mainQuestions.map((question) => [
                question?.questionNumber,
                question?.questionText || '',
                question?.response || '',
                getCommentsWithRatingGuide(question),
            ]) || [],
    };

    return params;
}

function getOptionalQuestions(appLabels: AppLabels, assessment: AssessmentViewModel) {
    const params: { [key: string]: any } = {};

    const baseTableParams = {
        columns: [
            appLabels.assessment.results.generate_report.parameters.question_number,
            appLabels.assessment.results.generate_report.parameters.question,
            appLabels.assessment.results.generate_report.parameters.response,
            appLabels.assessment.results.generate_report.parameters.comments,
        ],
        colWidth: [0.5, 2, 3, 4],
    };

    // Get optional sections that are visible for this assessment
    const shownOptionalSections = assessment.template?.sections?.filter((section) => {
        return isSectionConsideredOptional(section) && isSectionShown(section, assessment);
    });

    // Get the prompts for these sections
    const optionalPrompts: AssessmentPrompt[] = [];
    shownOptionalSections.forEach((section) =>
        section?.categories?.forEach((category) => category?.prompts?.forEach((prompt) => optionalPrompts.push(prompt)))
    );

    const assessmentMetadataProvider = getAssessmentMetadataProvider(assessment);

    params['[TABLE-other-responses]'] = {
        ...baseTableParams,
        data:
            optionalPrompts.map((prompt) => [
                assessmentMetadataProvider.getPromptNumber(prompt?.id),
                prompt?.label?.text || '',
                getResponseValue(prompt, appLabels),
                getResponseComments(prompt?.response, prompt?.responseType),
            ]) || [],
    };

    return params;
}

async function getAssessmentData(
    appLabels: AppLabels,
    assessment: AssessmentViewModel,
    colorizer: Colorizer,
    locale: string,
    rsIsEnabled: boolean,
    refIdToRecommendationsMap: { [key: string]: RecommendationOutput[] }
) {
    let params: { [key: string]: any } = {};
    params['[Customer Name]'] = assessment.accountCustomerName;
    params['[Assessment Type]'] = assessment.template?.title || '';
    params['[Assessment Code]'] = assessment.type;

    params = {
        ...params,
        ...getDates(assessment, locale),
        ...(await getContacts(assessment)),
        ...getDetailsTable(appLabels, assessment),
        ...getResponsesTable(appLabels, assessment),
        ...getRecommendedActionsTable(appLabels, assessment, rsIsEnabled, refIdToRecommendationsMap),
        ...getDetails(appLabels, assessment, colorizer, rsIsEnabled, refIdToRecommendationsMap),
        ...getRatings(appLabels, assessment),
        ...getNonRatingQuestions(appLabels, assessment),
        ...getOptionalQuestions(appLabels, assessment),
    };

    // Add radar if not disabled
    if (!assessment.template?.defaults?.report?.radar?.disabled) {
        params = {
            ...params,
            ...getRadars(appLabels, assessment),
        };
    }

    // Add heatmap if not disabled
    if (!assessment.template?.defaults?.report?.heatmap?.disabled) {
        params = {
            ...params,
            ...getHeatmap(appLabels, assessment, colorizer),
        };
    }

    // Add bar charts if not disabled
    if (!assessment.template?.defaults?.report?.scoreBarChart?.disabled) {
        params = {
            ...params,
            ...getBarCharts(appLabels, assessment, colorizer),
        };
    }

    return params;
}

export const generateDocxReport = async (
    appLabels: AppLabels,
    assessment: AssessmentViewModel,
    colorizer: Colorizer,
    templateId: string,
    locale: string,
    isPartner: boolean,
    rsIsEnabled: boolean,
    refIdToRecommendationsMap: { [key: string]: RecommendationOutput[] }
): Promise<string> => {
    let params = await getAssessmentData(appLabels, assessment, colorizer, locale, rsIsEnabled, refIdToRecommendationsMap);
    params = await addCustomizations(params, isPartner, appLabels);
    return generateReport(templateId, params);
};
