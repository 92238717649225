import { AssessmentPrompt } from '@amzn/aws-assessment-template-management-service-typescript-client';
import {
    AssessmentApplicability,
    AssessmentStatus,
    MetadataInput,
    Opportunity,
    UpdateAssessmentInput,
} from '@amzn/awscat-aws-assessment-service-typescript-client';

import { dateISOToUSFormat } from '../../../common/Utils';
import { CustomerProfile } from '../../../models/CustomerProfile';
import { CustomerViewModel } from '../../customers/CustomerViewModel';

export interface AssessmentCreateState {
    account: CustomerViewModel | null | undefined;
    description: string;
    /* applicability is for the A2S version of applicability. It should be used in the update flow */
    applicability: AssessmentApplicability;
    /* applicabilityPrompts is the Template Management version of applicability. It should be used in the create flow */
    applicabilityPrompts: AssessmentPrompt[] | null;
    /** This field is deprecated by Opportunity ID project. Should still be editable for assessments that have it set */
    mapProgramEngagementId: string;
    opportunity: Opportunity;
    internalContact: string;
    workshopDate: string;
    readoutDate: string;
    deliveredBy: {
        label?: string;
        value?: string;
    } | null;
    isDemoTest: boolean;
    type: string;
    version: string;
    status: AssessmentStatus | null;
    isReadOnly: boolean;
    optOutSolutionsRecommendation: boolean;
    customerProfile: CustomerProfile | null;
    metadata: MetadataInput[];
}

export const assessmentCreateStateToAssessmentInput = (state: AssessmentCreateState): UpdateAssessmentInput => {
    const metadata = state.metadata.filter((m) => !!m.value);
    const assessmentInput: UpdateAssessmentInput = {
        status: state.status,
        description: state.description,
        targetSegmentId: state.customerProfile?.targetSegmentId,
        isDemoTest: state.isDemoTest,
        workshopDate: dateISOToUSFormat(state.workshopDate) ?? undefined, // This is to provide backward compatibility to A2T 1.0
        readoutDate: dateISOToUSFormat(state.readoutDate) ?? undefined, // This is to provide backward compatibility to A2T 1.0
        mapProgramEngagementId: state.mapProgramEngagementId || undefined, // exclude falsy values (e.g. empty string)
        opportunity: state.opportunity || undefined,
        deliveredBy: state.deliveredBy.value || undefined, // exclude falsy values (e.g. empty string)
        internalContact: state.internalContact || undefined, // exclude falsy values (e.g. empty string)
        optOutSolutionsRecommendation: state.optOutSolutionsRecommendation,
        metadata,
    };
    return assessmentInput;
};

export const defaultAssessmentCreateState: AssessmentCreateState = {
    account: null,
    description: '',
    applicability: null,
    applicabilityPrompts: null,
    mapProgramEngagementId: '',
    opportunity: null,
    internalContact: '',
    workshopDate: '',
    readoutDate: '',
    deliveredBy: null,
    isDemoTest: false,
    type: '',
    version: '',
    status: null,
    isReadOnly: false,
    optOutSolutionsRecommendation: false,
    customerProfile: {
        targetSegmentId: null,
        customerCloudMaturity: null,
        customerPlanAndAlignmentExists: null,
    },
    metadata: [],
};
