import { Engagement, EngagementInput, Opportunity, OpportunityInput } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { Engagement as CasEngagement, Opportunity as CasOpportunity } from '@amzn/customer-account-service-typescript-client';

/* Functions for converting opportunities to selection options, and vice versa */

/**
 * Converts CAS opportunity to selection option
 * @param opportunity CAS opportunity
 * @returns selection option
 */
export const casOpportunityToSelectionOption = (opportunity: CasOpportunity): OptionDefinition => ({
    label: opportunity.opportunityName,
    value: opportunity.opportunityId,
});

/**
 * Converts opportunity to selection option
 * @param opportunity opportunity
 * @returns selection option
 */
export const opportunityToSelectionOption = (opportunity: Opportunity): OptionDefinition => ({
    label: opportunity.name,
    value: opportunity.id,
});

/**
 * Converts selection option to opportunity
 * @param selectionOption selection option
 * @returns opportunity
 */
export const selectionOptionToOpportunity = (selectionOption: OptionDefinition): OpportunityInput => ({
    id: selectionOption.value,
    name: selectionOption.label,
});

/* Functions for converting engagements to selection options, and vice versa */

/**
 * Converts CAS engagement to selection option
 * @param engagement CAS engagement
 * @returns selection option
 */
export const casEngagementToSelectionOption = (engagement: CasEngagement): OptionDefinition => ({
    label: engagement.sfdcEngagementName,
    value: engagement.sfdcEngagementRecordId,
});

/**
 * Converts engagement to selection option
 * @param engagement engagement
 * @returns selection option
 */
export const engagementToSelectionOption = (engagement: Engagement): OptionDefinition => ({
    label: engagement.name,
    value: engagement.id,
});

/**
 * Converts selection option to engagement
 * @param selectionOption selection option
 * @returns engagement
 */
export const selectionOptionToEngagement = (selectionOption: OptionDefinition): EngagementInput => ({
    id: selectionOption.value,
    name: selectionOption.label,
});
